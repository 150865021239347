<template>
  <v-row class="requests-view-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card
        v-if="requestData.corporate"
        class="pt-10"
      >
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="requestData.corporate.icon ? '' : 'primary'"
            :class="requestData.corporate.icon ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="requestData.corporate.icon"
              :src="require(`@/assets/images/company/${requestData.corporate.icon}`)"
            ></v-img>
            <span
              v-else
              class="font-weight-semibold text-5xl"
            >{{ avatarText(requestData.corporate.name) }}</span>
          </v-avatar>

          <span
            class="mb-2"
            style="font-size:0.9em"
          >{{ requestData.corporate.name }}</span>

          <v-chip
            label
            small
            color="secondary"
            class="small v-chip-light-bg text-sm font-weight-semibold success--text"
            style="font-size:0.9em"
          >
            {{ requestData.corporate.location }}
          </v-chip>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Corporate Details
          </h2>
          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Branch:</span>
              <span class="text--secondary text--truncate">{{ requestData.corporate.location }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Type:</span>
              <span class="text--secondary">{{ requestData.is_csr ? 'CSR Request' : 'Staff Request' }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Quantity:</span>
              <span class="text--secondary">{{ requestData.quantity }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Request Details
          </h2>

          <v-divider></v-divider>
          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Request Date:</span>
              <span class="text--secondary">{{ formatToDate(requestData.created_at) }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Status:</span>
              <v-chip
                small
                :color="resolveStatusVariant(requestData.status.name)"
                :class="`${resolveStatusVariant(requestData.status.name)}--text`"
                class="v-chip-light-bg font-weight-semibold text-capitalize"
              >
                {{ requestData.status.name }}
              </v-chip>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Price:</span>
              <span class="text--secondary">${{ formatToAccounting(requestData.price || 10) }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Amount:</span>
              <span class="text--secondary">${{ formatToAccounting(resolveVaccineAmount(requestData)) }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { avatarText, formatToDate, formatToAccounting } from '@core/utils/filter'
import resolvers from '@/mixins/resolvers'
export default {
  components: {},
  mixins: [resolvers],
  props: {
    requestData: {
      type: Object,
      required: true,
    },
    resolveVaccineAmount: {
      type: Function,
      required: true,
    },
  },
  setup() {
    return {
      avatarText,
      formatToDate,
      formatToAccounting,
    }
  },
}
</script>
