export default function useRequestsView() {
  //Amount Resolver
  const resolveVaccineAmount = item => {
    var price = item.price ?? 10
    var quantity = item.quantity ?? 0

    if (price == 0) price = 10
    return price * quantity
  }

  return { resolveVaccineAmount }
}
