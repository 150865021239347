var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"requests-vaccination-tab"}},[(_vm.requestData.corporate)?_c('v-card',{staticClass:"mb-6",attrs:{"title":"Colors"}},[_c('v-card-title',[_vm._v(" Patient Vaccinations ")]),_c('v-data-table',{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.requestData.corporate.vaccinations.filter(function (item) { return item.vaccine_request_id == _vm.requestData.id && item.date_administered; }),"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.date_administered == null ? '01/12' : item.date_administered))])]}},{key:"item.patient",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('p',{staticClass:"font-weight-medium text--primary mb-n1"},[_vm._v(" Name Withheld ")])])]}},{key:"item.batch",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.batch_no == null ? 'N/A' : item.batch_no))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveStatusVariant(item.vaccination_status.name)) + "--text"),attrs:{"small":"","color":_vm.resolveStatusVariant(item.vaccination_status.name)}},[_vm._v(" "+_vm._s(item.vaccination_status.name)+" ")])]}},{key:"item.request",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text--secondary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name: 'corporates-requests-view', params: { id: item.vaccine_request_id }}}},[_vm._v(" "+_vm._s(item.vaccine_request_id.split('-')[0].toUpperCase())+" ")])]}},{key:"item.narration",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.vaccination_status.description))])]}}],null,true)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }