import axios from '@axios'

export default {
  namespaced: true,
  state: {
    selectedRequest: {},
  },
  getters: {},
  mutations: {
    addSelectedUser(state, item) {
      state.selectedRequest = item
    },
  },
  actions: {
    fetchRequests(ctx, queryParams) {
      const limit = queryParams.options.itemsPerPage
      const offset = (queryParams.options.page - 1) * limit

      return new Promise((resolve, reject) => {
        axios
          .get(`/vaccine-request?limit=${limit}&offset=${offset}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRequest(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/vaccine-request/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
