<template>
  <div id="requests-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="3"
      >
        <requests-view-bio-panel
          :request-data="requestData"
          :resolve-vaccine-amount="resolveVaccineAmount"
        ></requests-view-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="9"
      >
        <v-tabs
          v-model="requestTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="requests-tabs-content"
          v-model="requestTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <requests-view-vaccinations
              :request-data="requestData"
              :resolve-vaccine-amount="resolveVaccineAmount"
            >
            </requests-view-vaccinations>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { onUnmounted, ref } from '@vue/composition-api'
import { mdiClipboardClockOutline } from '@mdi/js'
import router from '@/router'
import store from '@/store'
import storeModule from '../StoreModule'
import useRequestsView from './useRequestsView'
import RequestsViewBioPanel from './requests-view-bio-panel/RequestsViewBioPanel.vue'
import RequestsViewVaccinations from './requests-view-vaccinations/RequestsViewVaccinations.vue'

export default {
  components: {
    RequestsViewBioPanel,
    RequestsViewVaccinations,
  },
  setup() {
    const VACCINE_REQUEST_MODULE_NAME = 'requests'
    if (!store.hasModule(VACCINE_REQUEST_MODULE_NAME)) store.registerModule(VACCINE_REQUEST_MODULE_NAME, storeModule)

    const requestTab = ref(null)
    const requestData = ref({})
    const { resolveVaccineAmount } = useRequestsView()

    store
      .dispatch('requests/fetchRequest', { id: router.currentRoute.params.id })
      .then(response => {
        requestData.value = response.data.result
      })
      .catch(error => {
        if (error.response === 404) {
          requestData.value = {}
        }
      })

    const tabs = [{ icon: mdiClipboardClockOutline, title: 'Vaccinations' }]

    onUnmounted(() => {
      if (store.hasModule(VACCINE_REQUEST_MODULE_NAME)) store.unregisterModule(VACCINE_REQUEST_MODULE_NAME)
    })

    return {
      requestTab,
      requestData,
      tabs,
      resolveVaccineAmount,
    }
  },
}
</script>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
#requests-view #requests-tabs-content {
  background-color: transparent;
}
</style>

